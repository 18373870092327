import { gql } from ':src/__generated__/gql';

export const organizationMatchesByOrganizationIdQuery = gql(/* GraphQL */ `
  query OrganizationMatchesByOrganizationIdQuery($matchStatusId: Int, $programId: String) {
    organizationMatchesByOrganizationId(matchStatusId: $matchStatusId, programId: $programId) {
      result {
        matchStatusId
        supervisor {
          id
          firstName
          lastName
          displayName
          smallAvatar
          supervisorProfile {
            supervisorBandwidthId
          }
          usersLicenses {
            license {
              id
              displayName
            }
            usState {
              abbreviation
            }
          }
        }
      }
    }
  }
`);

export const usersByOrganizationId = gql(/* GraphQL */ `
  query UsersByOrganizationId(
    $limit: Int!
    $offset: Int!
    $organizationId: Int!
    $filter: UsersByOrganizationIdQueryFilter
    $searchQuery: String
    $sortByMostRecentSession: Boolean
    $programId: String
    $usStateId: Int
    $isRemovedTab: Boolean!
  ) {
    usersByOrganizationId(
      limit: $limit
      offset: $offset
      organizationId: $organizationId
      filter: $filter
      searchQuery: $searchQuery
      sortByMostRecentSession: $sortByMostRecentSession
      programId: $programId
      usStateId: $usStateId
    ) {
      result {
        id
        firstName
        lastName
        fullName
        mediumAvatar
        email @skip(if: $isRemovedTab)
        supervisorMatches(activeOnly: false) {
          id
          supervisorId
          matchStatusId
          supervisor {
            id
            displayName
          }
        }
        organization @skip(if: $isRemovedTab) {
          id
          name
        }
        organizationUser(deletedForOrganizationId: $organizationId) {
          id
          userId
          billingCode
          matchEmailSentAt
          scheduledForRemovalOn
          deletedAt
          recentSession {
            id
            scheduledAt
          }
          upcomingSession {
            id
            scheduledAt
          }
          approvedBySupervisorAt
          organizationProgram {
            id
            name
          }
          unmatchSuperviseeReason @include(if: $isRemovedTab) {
            id
            name
          }
          removalReasonText @include(if: $isRemovedTab)
        }

        organizationSuperviseeOnboardingRequest @skip(if: $isRemovedTab) {
          usState {
            id
            name
            abbreviation
          }
          licenseCategory {
            id
            name
            displayName
            abbreviation
          }
        }
      }
      total
    }
  }
`);

export const organizationSuperviseeOnboardingOptionsQuery = gql(/* GraphQL */ `
  query OrganizationSuperviseeOnboardingOptionsQuery {
    usStates {
      id
      name
    }
    licenseCategories {
      id
      displayName
    }
  }
`);

export const supervisorBandwidthsQuery = gql(/* GraphQL */ `
  query SupervisorBandwidths {
    supervisorBandwidths {
      id
      name
    }
  }
`);

export const bouncedEmailsQuery = gql(/* GraphQL */ `
  query BouncedEmailsQuery(
    $limit: Int!
    $offset: Int!
    $searchQuery: String
    $organizationId: Int
  ) {
    bouncedEmails(
      limit: $limit
      offset: $offset
      searchQuery: $searchQuery
      organizationId: $organizationId
    ) {
      result {
        user {
          id
          fullName
          email
          organizationUser {
            id
            organization {
              id
              name
            }
          }
        }
        error
      }
      total
    }
  }
`);
